import React from 'react';
import '../App.css';
//import { Button } from './Button';
//import './video.css';

function HeroSection() {
    return (

        <div id="video-popup" class="popup-modal mfp-hide">

            <div class="fluid-video-wrapper">
                <video controls>
                    <source id="mp4_src"
                        src="./videos/v1.mp4"
                        type='./video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
                    </source>
                    {/*<a class="close-popup">Close</a>         */}
                </video>

            </div>



        </div>

    );
}

export default HeroSection;
