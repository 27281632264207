import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
//import Traslation from "../config/Translation";
function MyComponentp(props) {
   const { t } = useTranslation();
   
   //let tit = 'contacto.0.title'
   let name = 'contacto.1.name'
   let email = 'contacto.2.email'
   let emaffairail = 'contacto.3.emaffairail'
   let emaiemssagel = 'contacto.4.emssage'
   let button = 'contacto.5.button'
  // let error = 'contacto.6.error'
   let msgok = 'contacto.7.msgok'
   let titdat = 'contacto.9.titdat'
   let eemp = 'contacto.10.eemp'
   let direcion = 'contacto.11.direcion'
   let municipio = 'contacto.12.municipio'
   let tel = 'contacto.13.tel'
   let tel1 = 'contacto.14.tel1'
   let email1 = 'contacto.15.email1'
   return(
     <>
     <div className="eight columns">
   {/*  <form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>
                  <div>
						   <label htmlFor="contactName">{t(name)} <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" />
                  </div>
                  <div>
						   <label htmlFor="contactEmail">{t(email)}<span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" />
                  </div>
                  <div>
						   <label htmlFor="contactSubject">{t(emaffairail)}</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" />
                  </div>
                  <div>
                     <label htmlFor="contactMessage">{t(emaiemssagel)} <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                  </div>
                  <div>
                     <button className="submit">{t(button)}</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>
*/}
           <div id="message-warning">{t(msgok)}</div>
				   <div id="message-success">
                  <i className="fa fa-check"></i>{t(msgok)}<br />
				   </div>
               </div>

               <aside className="four columns footer-widgets">



 

{/*
               <div className="portada" >
               <br/><br/><br/><br/><br/><br/>
               <img alt="" class="nav-wrap-logo1" src="../images/logo_b.png" width="80px" height="80px"></img>
               </div>
               <div className="targeta" >
               <br/><br/><br/><br/><br/><br/>
               
               </div>*/}

               {/*<div className="widget widget_contact" >*/}
               <div className="portada1" >
					   <h4>{t(titdat)}</h4>
					   
                  <p className="address">
                  <span className="fa fa-home"></span>&nbsp;&nbsp;
                  {t(eemp)}<br />
                  {t(direcion)} <br />
                  {t(municipio)}<br />
                  <span className="fa fa-envelope"></span> &nbsp; <span >{t(email)}</span>
                  <br/>
                  <span className="fa fa-envelope"></span>&nbsp;&nbsp; <span >{t(email1)}</span>
                  <br/>
                  <span className="fa fa-phone-square"></span> <span >{t(tel)}</span>
                     <br/>
                    <span className="fa fa-phone"></span>  <span>{t(tel1)}</span>
					   </p>
                  {/*<ul className="social">
            <i className="fa fa-phone"></i>
            <i className="fa fa-map-marker"></i>
            <i className="fa fa-globe"></i>
            </ul>*/}
            
				   </div>

            </aside>

     </>
     )
         
     }
 
class Contact extends Component {
  render() {

    if(this.props.data){
      /*var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;*/
    }

    return (
      <section id="contacto">
         <div className="row section-head">
            <div className="two columns header-col">
             {/*  <h4><span>contacto.</span></h4>*/}
            </div>
         </div>
         <div className="row">
         <MyComponentp/>
            
            
      </div>
   </section>
    );
  }
}

export default Contact;
