import Traslation from "i18next";
import { initReactI18next } from "react-i18next";

//const useLang = navigator.language || navigator.uselanguace;
//const defaultLocate = useLang.substring(0.2);
Traslation
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            lng: {
                translation: {
                    menu: [
                        {
                            "href": "#principal",
                            "class": "current",
                            "class1": "smoothscroll",
                            "descripcion": "Home"
                        },
                        {
                            "href": "#nosotros",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "About us"
                        },
                        {
                            "href": "#servicios",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Services"
                        },
                        {
                            "href": "#portfolio",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Porfolio"
                        },
                        {
                            "href": "#contacto",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Contact"
                        }
                    ],
                    principal: [
                        {
                            "title": "PROGRAMMING THE FUTURE",
                            "txt1": "",
                            "txt2": "",
                            "txt3": "",
                            "button": "Watch video"
                        }
                    ],
                    nosotros: [
                        { title: "¿Why A-Z Automation?" },
                        { line: 'We are a young company formed in 2018. We have engineers with extensive experience in' },
                        { line: 'different areas of the industry. We specialize in intelligent control projects with PLCs and' },
                        { line: "Robots, improvement of production processes, as well as technical support" },
                        { line: "We focus in the specific customer needs" }
                    ],
                    nosotros1: [
                        { title: "About us" },
                        { line: 'We have 15 years of experience in industrial automation and in the maintenance area.' },
                        { line: 'We specialize in projects, intelligent motor control, engineering solutions and technical support.' },
                        { line: "Improvement of production processes through the different stages of the production cycle. Review of technical documentation and scope of projects with clients. Post-sale technical service." },
                        { line: "" }
                    ],
                    misionision: [
                        { title: "Mission" },
                        { title: "View" },
                        { line: 'Provide our clients with the best automation service with electronic and mechanical devices to provide concrete and definitive solutions for series production.' },
                        { line: 'To be a leading company in process automation, provide the most advanced technology and excellence in service to our clients.' }
                    ],
                    contacto: [
                        { title: "Contact" },
                        { name: "Name" },
                        { email: "soporte@a-zautomation.com.mx" },
                        { emaffairail: "Affair" },
                        { emssage: "Mensaje" },
                        { button: "Send" },
                        { error: "Error" },
                        { msgok: "Er, your message was sent, thank you! Ror" },
                        { titinfcont: "Erro Contactor information" },
                        { titdat: "Contact information" },
                        { eemp: "A-Z Automation" },
                        { direcion: "(Peñuelas 1504, El Refugio)" },
                        { municipio: "(Mexico), (Querétaro.)" },
                        { tel: "442 431 5075" },
                        { tel1: "442 1713 843" },
                        { email1: "ventas@a-zautomation.com.mx" },
                    ],
                    titsericios: [
                        { title: "Services" }
                    ],
                    sericios: [
                        { title: "" },
                        { image: "../images/sericios/m_1.png" },
                        { detail: "" },
                        { title: "We Program" },
                        { image: "../images/sericios/s_1.png" },
                        { detail: "PLCs, HMI and robots" },
                        { title: "We Configure" },
                        { image: "../images/sericios/s_2.png" },
                        { detail: "Industrial Networks (Ethernet, Devicenet, Profibus, controlnet, Asibus, Modbus)" },
                        { title: "" },
                        { image: "../images/sericios/m_3.png" },
                        { detail: "" },
                        { title: "We Develop" },
                        { image: "../images/sericios/s_3.png" },
                        { detail: "Control Panels, electrical drawings, aluminium workbenches" },
                        { title: "" },
                        { image: "../images/sericios/m_4.png" },
                        { detail: "PLCs, HMI and robots" },
                        { title: "" },
                        { image: "../images/sericios/m_5.png" },
                        { detail: "" },

                    ],
                    portafoliotit: [
                        { title: "Briefcase" },
                    ],
                    portafolio: [
                        { title: "PLC programming project" },
                        { image: "../images/portafolio/programacion_de_plc.jpg" },
                        { detail: "Siemens with Fanuc, Profinet and I / O Link robots. Metalsa" },
                        { title: "PLC programming project" },
                        { image: "../images/portafolio/programacion_de_plc1.jpg" },
                        { detail: "Siemens with Fanuc, Profinet and I / O Link robots. Metalsa" },
                        { title: "Start-up project" },
                        { image: "../images/portafolio/Proyecto_de_puesta_en_marcha.jpg" },
                        { detail: "cell with Allen-Bradley CNC and PLC machines. American axle" },
                        { title: "Technical support in modification of" },
                        { image: "../images/portafolio/Soporte_tecnico.jpg" },
                        { detail: "consoles fixture. Allen-Bradley PLC. Yanfeng" },
                        { title: "Commissioning of machine" },
                        { image: "../images/portafolio/Puesta_en_Marcha.jpg" },
                        { detail: "Rotary cut with Allen-Bradley PLC, Safety Pilz PLC and Fanuc servo. Yanfeng" },
                        { title: "Commissioning of machine" },
                        { image: "../images/portafolio/Puesta_en_Marcha_corte.jpg" },
                        { detail: "cutting aluminum parts with Siemens PLC. Baomarc" },
                        { title: "Insertion of new model for" },
                        { image: "../images/portafolio/Insercion_de_nuevo_modelo_para_interiores.jpg" },
                        { detail: "Automotive door interiors with Atlas Copco Torque Meters, Allen-Bradley PLC and HMI. Yanfeng." }


                    ]
                }
            },
            es: {
                translation: {
                    menu: [
                        {
                            "href": "#principal",
                            "class": "current",
                            "class1": "smoothscroll",
                            "descripcion": "Principal"
                        },
                        {
                            "href": "#nosotros",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Acerca de nosotros"
                        },
                        {
                            "href": "#servicios",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Servicios"
                        },
                        {
                            "href": "#portfolio",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Portafolio"
                        },
                        {
                            "href": "#contacto",
                            "class": "",
                            "class1": "smoothscroll",
                            "descripcion": "Contacto"
                        }
                    ],
                    principal: [
                        {
                            "title": "PROGRAMANDO EL FUTURO",
                            "txt": "",
                            "txt1": "",
                            "txt2": "",
                            "txt3": "",
                            "button": "Ver video"
                        }

                    ],
                    nosotros: [
                        { title: "¿Por que elejir  A-Z Automation?" },
                        { line: 'Somos una empresa joven formada en 2018. Contamos con ingenieros con amplia experiencia en ' },
                        { line: 'distintas áreas de la industria. Nos especializamos en proyectos de control inteligente con PLCs y' },
                        { line: "Robots, mejora de procesos de producción, así como soporte técnico." },
                        { line: "Nos enfocamos en las necesidades específicas del cliente" }
                    ],
                    nosotros1: [
                        { title: "A cerca de nosotros" },
                        { line: 'Contamos con 15 años de experiencia en automatización industrial y en el área de mantenimiento.' },
                        { line: 'Nos especializamos en proyectos, control inteligente de motores, soluciones de ingeniería y soporte técnico.' },
                        { line: "Mejora de procesos de producción a través de las distintas etapas del ciclo de producción. Revisión de documentación técnica y alcance de proyectos con clientes. Servicio técnico post venta." },
                        { line: "" }
                    ],
                    misionision: [
                        { title: "Misión" },
                        { title: "Visión" },
                        { line: 'Proveer a nuestros clientes con el mejor servicio de automatización con dispositivos electrónicos y mecánicos para dar soluciones concretas y definitivas para la producción en serie.' },
                        { line: 'Ser una empresa líder en automatización de procesos, proveer de la tecnología más avanzada y excelencia en servicio a nuestros clientes.' }
                    ],
                    contacto: [
                        { title: "Contacto" },
                        { name: "Nombre" },
                        { email: "soporte@a-zautomation.com.mx" },
                        { emaffairail: "Asunto" },
                        { emssage: "Mensaje" },
                        { button: "Enviar" },
                        { error: "Error" },
                        { msgok: "Er¡Tu mensaje fue enviado, gracias!ror" },
                        { titinfcont: "ErroInformación de contactor" },
                        { titdat: "Información de contacto" },
                        { eemp: "A-Z Automation" },
                        { direcion: "Peñuelas 1504, El Refugio" },
                        { municipio: "Mexico, Querétaro." },
                        { tel: "52 1 442 431 5075" },
                        { tel1: "442 1713 843" },
                        { email1: "ventas@a-zautomation.com.mx" },
                    ],
                    titsericios: [
                        { title: "Sericios" }
                    ],
                    sericios: [
                        { title: "" },
                        { image: "../images/sericios/m_1.png" },
                        { detail: "" },
                        { title: "Programamos" },
                        { image: "../images/sericios/s_1.png" },
                        { detail: "PLC,HMI y robots" },
                        { title: "Configuramos" },
                        { image: "../images/sericios/s_2.png" },
                        { detail: "Redes industriales (Ethernet, Devicenet, Profibus, controlnet, Asibus, Modbus)" },
                        { title: "" },
                        { image: "../images/sericios/m_3.png" },
                        { detail: "" },
                        { title: "Desarrollamos" },
                        { image: "../images/sericios/s_3.png" },
                        { detail: "Paneles de control, planos eléctricos, bancos de trabajo de aluminio" },
                        { title: "" },
                        { image: "../images/sericios/m_4.png" },
                        { detail: "" },
                        { title: "" },
                        { image: "../images/sericios/m_5.png" },
                        { detail: "" },

                    ],
                    portafoliotit: [
                        { title: "Portafolio" },
                    ],
                    portafolio: [
                        { title: "Proyecto de programación de PLC " },
                        { image: "../images/portafolio/programacion_de_plc.jpg" },
                        { detail: "Siemens con robots Fanuc, Profinet y I/O Link. Metalsa" },
                        { title: "Proyecto de programación de PLC " },
                        { image: "../images/portafolio/programacion_de_plc1.jpg" },
                        { detail: "Siemens con robots Fanuc, Profinet y I/O Link. Metalsa" },
                        { title: "Proyecto de puesta en marcha" },
                        { image: "../images/portafolio/Proyecto_de_puesta_en_marcha.jpg" },
                        { detail: "celda con máquinas CNC y PLC Allen-Bradley. American Axle" },
                        { title: "Soporte tecnico en modificación de" },
                        { image: "../images/portafolio/Soporte_tecnico.jpg" },
                        { detail: "fixture de consolas. PLC Allen-Bradley. Yanfeng" },
                        { title: "Puesta en Marcha de máquina de" },
                        { image: "../images/portafolio/Puesta_en_Marcha.jpg" },
                        { detail: " corte giratoria con PLC Allen-Bradley, PLC Pilz de seguridad y servo Fanuc. Yanfeng" },
                        { title: "Puesta en Marcha de máquina de" },
                        { image: "../images/portafolio/Puesta_en_Marcha_corte.jpg" },
                        { detail: "corte de piezas de aluminio con PLC Siemens. Baomarc" },
                        { title: "Inserción de nuevo modelo para" },
                        { image: "../images/portafolio/Insercion_de_nuevo_modelo_para_interiores.jpg" },
                        { detail: "interiores de puertas automotrices con Torquímetros Atlas Copco, PLC y HMI Allen-Bradley. Yanfeng." }

                    ]
                }
            }
        },
        lng: "es",
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }

    });

export default Traslation;