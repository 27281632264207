import React, { Component } from 'react';
import axios from "axios";
import saveAs from "file-saver";
import HeroSection from './video';
//import Player from "./reactPlayer";
import { useTranslation } from 'react-i18next';
import Traslation from "../config/Translation";

function ConstMenu() {
    return MyComponentp(0)
}

function MyComponent() {
    return MyComponentp(1)

}

function MyComponentp(b) {
    let tx = Traslation
    if (tx === 0) {
        console.log()
    }
    const { t } = useTranslation();
    let compon = [];
    if (b === 0) {
        console.log('Costructor del menu')
        for (let i = 0; i < t('menu.length'); i++) {
            let clas = 'menu.' + i + '.class'
            let clas1 = 'menu.' + i + '.class1'
            let hreff = 'menu.' + i + '.href'
            let desc = 'menu.' + i + '.descripcion'
            compon.push(
                <li className={t(clas)}><a className={t(clas1)} href={t(hreff)}>{t(desc)}</a></li>
            );
        }
        return compon
    } else if (b === 1) {
        for (let i = 0; i < t('principal.length'); i++) {
            let tit = 'principal.' + i + '.title'
            let txt = 'principal.' + i + '.txt'
            let txt1 = 'principal.' + i + '.txt1'
            let txt2 = 'principal.' + i + '.txt2'
       //     let btn = 'principal.' + i + '.button'
            compon.push(<p >{t(tit)}</p>);
            compon.push(<h4>{t(txt)}</h4>);
            compon.push(<h4>{t(txt1)}</h4>);
            compon.push(<h4>{t(txt2)}</h4>);
         /*   compon.push(
                <a href="#modal-one" class="button btn btn-primary">{t(btn)}</a>
            )*/
        }
        return compon
    }
}

function HeaderComponent() {
    const { i18n } = useTranslation('common');
    let boton = <span id='idioma' onClick={() => changeLanguage('lng')} ><img id="img1" src="./images/banderas/pngwing.png" alt="x" width="21" height="21" />ESPAÑOL</span>
    const changeLanguage = lng => {
        let detimg = document.getElementById('idioma').innerText
        if (detimg === 'ESPAÑOL') {
            console.log("Accion click ")
            i18n.changeLanguage('lng');
            document.getElementById('idioma').innerHTML = '<img  id="img1" src="./images/banderas/klipartz.png" alt="x" width="21" height="21"/>ENGLISH'
        } else {
            document.getElementById('idioma').innerHTML = '<img  id="img1" src="./images/banderas/pngwing.png" alt="x" width="21" height="21"/>ESPAÑOL'
            i18n.changeLanguage('es');
        }
    };
    return <>
        {boton}
    </>
}


class Principal extends Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: true };
        // Este enlace es necesario para hacer que `this` funcione en el callback
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick() {
        console.log('Pensamos mostrar el modal');
        //modal.style.display = "block";
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }


    state = {
        open: false
    };

    onOpenModal = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    // don't do this
    downloadVideo = () => {
        let link = "https://vimeo.com/291715535";
        axios({
            url: link, //your url
            method: "GET",
            responseType: "blob" // important
        }).then(response => {
            var blob = new Blob([response.data], {
                type: "text/plain;charset=utf-8"
            });
            saveAs(blob, "hello world.txt");
        });
    };


    render() {

        if (this.props.data) {
            var networks = this.props.data.social.map(function (network) {
                return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
            })

        }

        return (
            <header id="principal">
                <nav id="nav-wrap">


                    {/*<img src="../images/logo2.png" alt=""  width="100px" height="100px" />*/}

                    {/*      <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
*/}
                    <ul id="nav" className="nav">
                        {/* <img alt="" class="nav-wrap-logo" src="../images/logo.png" width="200px" height="200px"></img>*/}
                        <span><b className="nasalization" >A-Z </b><b className="nasalization1">automation</b></span>
                        &nbsp;&nbsp;&nbsp;
                        <ConstMenu />
                        <HeaderComponent />
                    </ul>

                </nav>



                <HeroSection />
                <div className="row banner">
                    <div className="banner-text">

                        {/*<h1 className="responsive-headline"><img id="logo" src="../images/logo.png" alt=""  width="650px" height="650px" /></h1>*/}

                        <div className="video-link">
                            <span><b className="nasalizationt" >A-Z </b><b className="nasalizationt1">automation</b></span>
                        </div>
                        <MyComponent />
                        <hr />

                        <ul className="social">
                            {networks}
                        </ul>

                    </div>
                </div>


                <div class="modal" id="modal-one" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-header">
                            <h3>Video demo</h3>
                            <a href="/#" class="btn-close" aria-hidden="true">×</a>
                        </div>
                        <div class="modal-body">
                            <div class="video-container">
                                <video autoplay loop class="fillWidth visible-lg" id="vid">
                                    <source src="videos/v1.mp4" type="video/mp4; " />
                                    Your browser does not support the video tag.
                                </video>
                            </div>


                        </div>
                    </div>
                </div>



                <p className="scrolldown">
                    <a className="smoothscroll" href="#contacto"><i className="icon-down-circle"></i></a>
                </p>
            </header>
        );
    }
}

export default Principal;