import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
//import Traslation from "../config/Translation";
function MyComponentp(props) {
   const { t } = useTranslation();
  let compon = [];

  for (let i = 0; i < t('nosotros.length'); i++) 
  {
    if( i===0){
       let tit = 'nosotros.'+i+'.title'
    /*compon.push(
        <h2>{t(tit)}</h2>
      );*/
    }else {//if(i != 1){
       let line = 'nosotros.'+i+'.line'
       compon.push(
          <span>{t(line)}</span>
       )
       compon.push(
          <br/>
       )     
    }
 }
   /*    for (let i = 0; i < t('nosotros1.length'); i++) 
       {
         if( i==0){
            let tit = 'nosotros1.'+i+'.title'
         compon.push(
             <h2>{t(tit)}</h2>
           );
         }else {//if(i != 1){
            let line = 'nosotros1.'+i+'.line'
            compon.push(
               <span>{t(line)}</span>
            )
            compon.push(
               <br/>
            )     
         }
      }*/
        return compon
     }


     function MyComponentvision(props) {
      const { t } = useTranslation();
      
      let tit = 'misionision.0.title'
      let tit1 = 'misionision.1.title'
      let mision = 'misionision.2.line'
      let vision = 'misionision.3.line'
      return(
        <>
        <div className="columns contact-details">
              <h2>{t(tit)}</h2>
                    <span>{t(mision)}</span>
                    </div>
    
                    <div className="columns contact-details">
                  <h2>{t(tit1)}</h2>
                    <span>{t(vision)}</span>
                    </div>
        </>
        )
            
        }


class Nosotros extends Component {
  render() {

    if(this.props.data){
      //var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      /*var bio = this.props.data.bio;
      var bio1 = this.props.data.bio1;
      var bio2 = this.props.data.bio2;
      var mision = this.props.data.mision;
      var vision = this.props.data.vision;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var email1 = this.props.data.email1;
      var resumeDownload = this.props.data.resumedownload;*/
    }

    return (
      <section id="nosotros">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Tim Baker Profile Pic" />
         </div>
         <div className="nine columns main-col">
            
         <MyComponentvision/>
            {/*<h2>A cerca de nosotros</h2>
            <span>{bio}</span>
            <span>{bio1}</span>
            <span>{bio2}</span>*/}
            <div className="row">
       
            </div>
            
            
         </div>
      </div>
     </section>
    );

  }
}

export default Nosotros;
