import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
//import Traslation from "../config/Translation";

function MyComponentp() {
  const { t } = useTranslation();
 let compon = [];
     for (let i = 0; i < t('sericios.length'); i++) 
      {
           let tit = 'sericios.'+i+'.title'
           i++
           let image = 'sericios.'+i+'.image'
           i++
           let detail = 'sericios.'+i+'.detail'
        compon.push(
           <div class="slide">{t(tit)}<img alt="" src={t(image)}/>{t(detail)}</div>
          );
        }
     
        return compon
    }

class Servicios extends Component {
  render() {

    if(this.props.data){
   
    }

    return (
      <div class="container">
  
      <section id="servicios"  class="customer-logos slider">

      {/*<div className="row education">
         <div className="three columns header-col">
            <h1><span></span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {/*education*/}
       {/*          <MyComponentp/>
               </div>
            </div>
         </div>
      </div>*/}

      <MyComponentp/>


{/*
      <div className="row work">

         <div className="three columns header-col">
            <h1><span></span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>*/}
   </section>
   </div>
    );
  }
}

export default Servicios;
