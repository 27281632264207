import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

//import Traslation from "../config/Translation";

function MyComponentt() {
  const { t } = useTranslation();
  let tit = t('portafoliotit.0.title')
  return  <h1>{t(tit)}</h1>

}

function MyComponentp() {
  const { t } = useTranslation();
 let compon = [];
 //let tit = t('portafolio.0.title')
 //let b=3;
        for (let i = 0; i < t('portafolio.length'); i++) 
      {
           let tit = 'portafolio.'+i+'.title'
           i++
           let image = 'portafolio.'+i+'.image'
           i++
           let detail = 'portafolio.'+i+'.detail'
           
         //  let detail = 'portafolio.'+i+'.detail'
        compon.push(
           <div  className="columns portfolio-item">
           <div className="item-wrap">
               <img alt='' src={t(image)} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{t(tit)}</h5>
                     <p>{t(detail)}</p>
                  </div>
                </div>
          </div>
        </div>
          );
        }
        return compon
    }



class Portafolio extends Component {
  render() {

    
    return (
      <section id="portfolio">

      <div className="row">

         <div className="twelve columns collapsed">
           <MyComponentt/>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {/*{projects}*/}
                <MyComponentp/>
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portafolio;
